import React from 'react'

export const PoweredBy = (): React.JSX.Element => {
	return (
		<div className="powered-by-element">
			<p>
				Powered by:{' '}
				<a href="https://charityspurse.ai/" target="_blank" rel="noopener noreferrer">
					Charity&apos;s Purse
					<img src={`${process.env.PUBLIC_URL}/charityspurse.png`} alt="Charity's Purse logo" />
				</a>
			</p>
		</div>
	)
}
