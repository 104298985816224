import { camelCase, kebabCase } from 'lodash'
import { type TRecord } from '../interfaces'
import { getRequestParameters } from './common'
import { type IDonateWidgetStyle } from '../reducers'

export const style: TRecord<string> = {
	backgroundColor: '#fff',
	lightTextColor: '#fff',
	darkTextColor: '#000',
	baseColor: '#2980b9',
	secondaryColor: '#ecf0f1',
	lighterColor: '#3498db',
	darkerColor: '#8e44ad',
	errorColor: '#d63031'
}

const screenLgMin = 1240
const screenMdMin = 768
const screenSmMin = 480
const screenXsMin = 280

const breakpoints: TRecord<number> = {
	screenLgMin,
	screenMdMin,
	screenMdMax: screenLgMin,
	screenSmMin,
	screenSmMax: screenMdMin,
	screenXsMin,
	screenXsMax: screenSmMin
}

export const isMobile = (): boolean => window.innerWidth < breakpoints.screenSmMax

export const setStyle = (dbStyle: IDonateWidgetStyle): void => {
	const properties = getRequestParameters()

	const combinedStyles = { ...dbStyle, ...properties }

	Object.entries(combinedStyles).forEach(([key, value]) => {
		const normalizedKey = key.replace(/-/g, '_')
		const styleKey = `${camelCase(normalizedKey)}Color`

		if (value && typeof value === 'string') {
			style[styleKey] = value
		}
	})
}

export const getStyle = (): TRecord<string> => {
	const css: TRecord<string> = {}

	Object.entries(style).forEach(([key, value]) => {
		css[`--${kebabCase(key)}`] = value
	})

	return css
}
