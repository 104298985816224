import { type TRecord } from '../interfaces'

import { formatPrice } from './common'
const { REACT_APP_NAME, REACT_APP_API_BASE_URL, REACT_APP_STRIPE_PUBLISHABLE_KEY } = process.env as TRecord<string>

export const appName = REACT_APP_NAME
export const apiUrl = REACT_APP_API_BASE_URL
export const defaultWidgetAmount = 100 // if not available in preset_amounts, first amount will be used
export const stripePublishableKey = REACT_APP_STRIPE_PUBLISHABLE_KEY

export const debounceTime = 250
export const defaultHeroHeadline = 'Help our organization by donating today! All donations go directly to making a difference for our cause.'
// Form step defaults
export const defaultFormStepTargetTitle = 'Which fund do you want to donate to?'
export const defaultFormStepTargetSubTitle = 'You can select the specific fund to which your donation will go.'
export const defaultFormStepAmountTitle = 'How much would you like to donate today?'
export const defaultFormStepAmountSubTitle = 'All donations directly impact our organization and help us further our mission.'
export const defaultFormStepCustomAmountLabel = 'Custom Amount'
export const defaultFormStepUserFormTitle = 'Who is giving today?'
export const defaultFormStepUserFormSubtitle = 'We will never share this information with anyone.'
export const defaultFormStepFirstNameLabel = 'First name'
export const defaultFormStepLastNameLabel = 'Last name'
export const defaultFormStepEmailLabel = 'Email'
export const defaultFormStepPhoneNumberLabel = 'Phone number'
// eslint-disable-next-line max-len
export const defaultFormStepTransactionFeeConsentLabel = (fee: number): string =>
	`I'd like to help cover the transaction fees of ${formatPrice(fee)} for my donation.`
export const defaultFormStepRecurringConsentLabel = 'Make this donation every month'
export const defaultFormStepContinueButtonText = 'Continue…'
export const defaultFormStepSecurityGuaranteeText = '100% Secure Donation'
export const defaultFormStepErrorNotificationMessage = 'Error during the donation process. Please try again!'

// Payment step defaults
export const defaultPaymentStepCreditCardFormTitle = 'How would you like to pay today?'
export const defaultPaymentStepCreditCardFormSubTitle = 'This donation is a secure and encrypted payment.'
export const defaultPaymentStepDonationSummaryTitle = 'Donation summary'
export const defaultPaymentStepPaymentAmountLabel = 'Payment Amount'
export const defaultPaymentStepTransactionFeesLabel = 'Transaction Fees'
export const defaultPaymentStepGivingFrequencyLabel = 'Giving Frequency'
export const defaultPaymentStepDonationTotalLabel = 'Donation Total'
export const defaultPaymentStepDonationText = 'Donate Now'

export const defaultTotalDonationSubtext = 'raised'
export const defaultGoalAmountSubtext = 'out of'
