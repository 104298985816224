/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Button } from '../../../components/form'
import { Summary, Tag, Notification, type TEntry } from '../../../components/element'
import { FaLock } from 'react-icons/fa'
import React, { useContext, useState } from 'react'
import { DonateContext } from '../../../contexts'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import type { IFormStepForm } from '../steps'
import { formatPrice, scrollTop, getRequestParameters } from '../../../utils/common'
import { type TRecord } from '../../../interfaces'
import {
	defaultFormStepErrorNotificationMessage,
	defaultPaymentStepCreditCardFormTitle,
	defaultPaymentStepCreditCardFormSubTitle,
	defaultFormStepSecurityGuaranteeText,
	defaultPaymentStepDonationSummaryTitle,
	defaultPaymentStepPaymentAmountLabel,
	defaultPaymentStepTransactionFeesLabel,
	defaultPaymentStepGivingFrequencyLabel,
	defaultPaymentStepDonationTotalLabel,
	defaultPaymentStepDonationText
} from '../../../utils/defaults'

const {
	error_notification_message: errorNotificationMessage,
	credit_card_form_title: creditCardFormTitle,
	credit_card_form_subtitle: creditCardFormSubtitle,
	donation_summary_title: donationSummaryTitle,
	payment_amount_label: paymentAmountLabel,
	transaction_fees_label: transactionFeesLabel,
	giving_frequency_label: givingFrequencyLabel,
	donation_total_label: donationTotalLabel,
	donate_text: donateText,
	security_guarantee_text: securityGuaranteeText
} = getRequestParameters() as TRecord<string>

export interface IPaymentStepForm {
	donation_id: string
}

interface IPaymentStep {
	amount: number
	form: IFormStepForm
	handleAction: (form: IPaymentStepForm) => void
}

export const PaymentForm = ({ amount, form, handleAction }: IPaymentStep): React.JSX.Element => {
	const { state, dispatch } = useContext(DonateContext)

	const stripe = useStripe()
	const elements = useElements()
	const [loading, setLoading] = useState(false)
	const [validated, setValidated] = useState(true)

	const getSummaryEntries = (): TEntry[] => {
		// eslint-disable-next-line max-len
		const labels: TEntry[] = [
			[`${paymentAmountLabel ?? state?.donation?.content?.payment_amount_label ?? defaultPaymentStepPaymentAmountLabel}`, formatPrice(amount)]
		]

		if (form.fee && state?.fee) {
			labels.push([
				`${transactionFeesLabel ?? state?.donation?.content?.transaction_fees_label ?? defaultPaymentStepTransactionFeesLabel}`,
				formatPrice(state.fee.stripe_total_fee)
			])
		}

		labels.push([
			`${givingFrequencyLabel ?? state?.donation?.content?.giving_frequency_label ?? defaultPaymentStepGivingFrequencyLabel}`,
			form.recurring ? 'Monthly' : 'One time'
		])

		return labels
	}

	const getSummaryTotal = (): TEntry => {
		if (form.fee && state?.fee) {
			// eslint-disable-next-line max-len
			return [
				`${donationTotalLabel ?? state?.donation?.content?.donation_total_label ?? defaultPaymentStepDonationTotalLabel}`,
				formatPrice(state.fee.total_amount)
			]
		}

		return [
			`${donationTotalLabel ?? state?.donation?.content?.donation_total_label ?? defaultPaymentStepDonationTotalLabel}`,
			formatPrice(amount)
		]
	}

	const handleSubmit = (): void => {
		if (!stripe || !elements || !state?.donation || !state.definition) return

		setLoading(true)
		setValidated(true)

		const stripeAction = stripe.confirmPayment({
			elements,
			redirect: 'if_required'
		})

		stripeAction
			.then((response) => {
				setLoading(false)

				if (response.error) {
					setValidated(false)
					scrollTop()
					return
				}

				if (state.donation) {
					if (!dispatch || !state.definition) return

					handleAction({
						donation_id: state.definition?.donation?._id
					})
				}
			})
			.catch((e) => {
				setValidated(false)
				scrollTop()
			})
	}

	if (!state?.donation) return <></>

	return (
		<>
			<div className="donate-box">
				{(state.error || !validated) && (
					<>
						{state.error ? (
							<Notification type="error" title={errorNotificationMessage ?? defaultFormStepErrorNotificationMessage} />
						) : (
							<Notification type="error" title="Please fix errors in the form." />
						)}
					</>
				)}
				<div className="donate-header">
					<h2>{creditCardFormTitle ?? state?.donation?.content?.credit_card_form_title ?? defaultPaymentStepCreditCardFormTitle}</h2>
					<h4>
						{creditCardFormSubtitle ?? state?.donation?.content?.credit_card_form_subtitle ?? defaultPaymentStepCreditCardFormSubTitle}
					</h4>
				</div>
				<div className="donate-content">
					<PaymentElement />
				</div>
			</div>
			<div className="donate-box">
				<div className="donate-header">
					<h2>{donationSummaryTitle ?? state?.donation?.content?.donation_summary_title ?? defaultPaymentStepDonationSummaryTitle}</h2>
				</div>
				<div className="donate-content">
					<Summary entries={getSummaryEntries()} summary={getSummaryTotal()} />
				</div>
			</div>
			<div className="donate-box">
				<div className="donate-content">
					<Button
						text={donateText ?? state?.donation?.content?.donate_button_text ?? defaultPaymentStepDonationText}
						action={handleSubmit}
						loading={loading || state.loading}
					/>
					<div className="text-center">
						<Tag
							icon={<FaLock color="gold" />}
							text={securityGuaranteeText ?? state.donation?.content?.security_guarantee_text ?? defaultFormStepSecurityGuaranteeText}
						/>
					</div>
				</div>
			</div>
		</>
	)
}
