import React, { useState, useEffect } from 'react'

import { sanitizeAmount } from '../../utils/common'

import { defaultTotalDonationSubtext, defaultGoalAmountSubtext } from '../../utils/defaults'

export interface IProgressBar {
	goal: number
	current: number
	goalAmountSubtext?: string
	totalDonationSubtext?: string
}

export const ProgressBar = ({ goal, current, goalAmountSubtext, totalDonationSubtext }: IProgressBar): React.JSX.Element => {
	const [percentage, setPercentage] = useState(0)

	useEffect(() => {
		const newPercentage = (current / goal) * 100
		setPercentage(newPercentage)
	}, [current, goal])

	return (
		<div className="progress-bar-wrapper">
			<div className="current">
				<h3>
					${sanitizeAmount(current)} {totalDonationSubtext ?? defaultTotalDonationSubtext}
				</h3>
			</div>
			<div className="goal">
				<h4>
					{goalAmountSubtext ?? defaultGoalAmountSubtext} ${sanitizeAmount(goal)}
				</h4>
			</div>
			<div className="progress-bar">
				<div className="progress-bar-fill" style={{ width: `${percentage}%` }} />
			</div>
		</div>
	)
}
