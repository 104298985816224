import React, { useContext, useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { FaLock } from 'react-icons/fa'
import { Basic, Hero } from '../../components/section'
import { Widget } from '../../components/container'
import { Step, Steps, ProgressBar, PoweredBy } from '../../components/element'
import { calculateFeeDonation, fetchDonation, initDonation, confirmDonation } from '../../actions/donate'
import { DonateContext } from '../../contexts'
import { debounceTime, defaultHeroHeadline, defaultWidgetAmount, defaultFormStepSecurityGuaranteeText } from '../../utils/defaults'
import { FormStep, type IFormStepForm, PaymentStep, ThankYouStep } from './steps'
import { type IPaymentStepForm } from './components'
import {
	createHandleError,
	createHandleShowError,
	createValidate,
	getFullName,
	getRequestParameters,
	getUtmVariablesFromUrl,
	redirect,
	scrollTop
} from '../../utils/common'
import { setupWidget } from '../../utils/widget'
import { getGA4 } from '../../utils/ga4'
import { setStyle } from '../../utils/styles'
import { type TRecord } from '../../interfaces'
import { getFbPixel } from '../../utils/fbPixel'
import { type IDonateWidgetStyle, type IConnectAccount } from '../../reducers'

const steps = ['form', 'payment', 'thank_you']

let calculateFeeTimeout: number

export const DonateWidget = (): React.JSX.Element => {
	const { id, uuid } = useParams()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const variationId = queryParams.get('variation_id')

	const { state, dispatch } = useContext(DonateContext)

	const [amount, setAmount] = useState(0)

	const [form, setForm] = useState<IFormStepForm | null>(null)
	const [initialized, setInitialized] = useState(false)
	const [step, setStep] = useState(0)
	const [error, setError] = useState({})
	const [validated, setValidated] = useState(true)
	const [showError, setShowError] = useState(false)

	const handleError = createHandleError(setError)
	const handleShowError = createHandleShowError(setShowError)
	const validate = createValidate(error)

	const ga4 = getGA4()
	const fbPixel = getFbPixel()

	const {
		title: heroTitle,
		description: heroHeadline,
		security_guarantee_text: securityGuaranteeText,
		thank_you_page_url: thankYouPageUrl
	} = getRequestParameters() as TRecord<string>

	const handleAmountSet = (amount: number): void => {
		if (!dispatch) return

		setAmount(amount)

		window.clearTimeout(calculateFeeTimeout)
		calculateFeeTimeout = window.setTimeout(() => {
			calculateFeeDonation(dispatch, {
				country: 'US',
				is_fee_shouldered: true,
				amount: amount || 0
			})
		}, debounceTime)
	}

	const handleAction = (formStepData: IFormStepForm | IPaymentStepForm): void => {
		if (!dispatch || !id || !uuid) return

		if (!validate()) {
			handleShowError()
			setValidated(false)
			scrollTop()
			return
		}

		setValidated(true)

		let data = formStepData

		switch (step) {
			case 0:
				data = formStepData as IFormStepForm
				initDonation(
					dispatch,
					id,
					uuid,
					{
						card_country: 'US',
						is_fee_shouldered: data.fee,
						donation_amount: amount,
						target_connect_account_id: data.targetStripeConnectAccount,
						recurring: data.recurring
					},
					{
						name: getFullName(data.firstName, data.lastName),
						email: data.email,
						phone: data.phone,
						...getUtmVariablesFromUrl()
					}
				)

				setForm(data)
				break
			case 1:
				data = formStepData as IPaymentStepForm

				confirmDonation(dispatch, data.donation_id)
				break
			default:
				setStep(step + 1)
				break
		}
	}

	useEffect(() => {
		if (!dispatch || !id || !uuid) return

		fetchDonation(dispatch, id, variationId as string)
		setupWidget(uuid)
		setStyle({} satisfies IDonateWidgetStyle)

		if (ga4) {
			ga4.sendView({
				hitType: 'preview',
				page: window.location.pathname
			})
		}

		if (fbPixel) {
			fbPixel.sendView()
		}
	}, [id, uuid])

	useEffect(() => {
		if (!state) return

		if (!initialized) {
			if (state.error) {
				window.location.href = '/404'
			}

			if (state.donation) {
				if (state.donation.preset_amounts.includes(defaultWidgetAmount)) {
					handleAmountSet(defaultWidgetAmount)
				} else {
					handleAmountSet(state.donation.preset_amounts[0])
				}
				if (state.donation.style) {
					setStyle(state.donation?.style)
				}

				setInitialized(true)
			}
		}

		if (initialized && state.definition) {
			setStep(1)
		}
	}, [state?.donation, state?.error, state?.definition])

	useEffect(() => {
		if (!state?.confirmed || !state.donation) return

		if (state.fee && state.definition && form) {
			let targetAccount = ''

			if (form.targetStripeConnectAccount && state?.donation?.connect_accounts) {
				const foundTargetAccount = state.donation.connect_accounts.find(
					(account: IConnectAccount) => account?.stripe_account_id === form.targetStripeConnectAccount
				)

				if (foundTargetAccount) {
					targetAccount = foundTargetAccount?.display_name ?? `${foundTargetAccount.email} ${foundTargetAccount.stripe_account_id}`
				}
			}

			if (ga4) {
				ga4.sendEvent('donate', {
					category: 'Fundraising',
					action: 'Donate',
					label: state.donation.name,
					value: state.fee.donation_amount,
					item_id: state.donation._id,
					item_name: state.donation.name,
					item_brand: 'Fundraising',
					affiliation: 'Fundraising',
					item_category: 'Donations',
					item_category_2: 'Stripe',
					item_category_3: state.definition.donation.payment_details.recurring ? 'Recurring' : 'One Time',
					item_target_account: targetAccount
				})
			}

			if (fbPixel) {
				fbPixel.sendEvent('Purchase', {
					currency: 'USD',
					value: state.fee.donation_amount,
					type: state.definition.donation.payment_details.recurring ? 'Recurring' : 'One Time',
					target_account: targetAccount
				})
			}
		}

		const { success } = getRequestParameters()

		if (success) {
			redirect(success as string)
			return
		} else if (thankYouPageUrl || state.donation.thank_you_page_url) {
			redirect(thankYouPageUrl || state.donation.thank_you_page_url)
			return
		}

		setStep(2)
	}, [state?.confirmed, state?.donation, state?.fee, state?.definition, form])

	if (!initialized || !state || !state.donation) return <></>

	return (
		<Widget>
			<Hero
				title={heroTitle || state.donation.name || state.donation.title}
				headline={heroHeadline || state.donation?.description || defaultHeroHeadline}
				tag={{
					icon: <FaLock color="gold" />,
					label: `${securityGuaranteeText ?? state.donation?.content?.security_guarantee_text ?? defaultFormStepSecurityGuaranteeText}`
				}}
			/>
			<>
				{state.donation.goal_amount_enabled ? (
					<ProgressBar
						goal={state.donation?.goal_amount ?? 0}
						current={state.donation?.total_donation_amount ?? 0}
						goalAmountSubtext={state.donation?.goal_amount_subtext}
						totalDonationSubtext={state.donation?.total_donation_subtext}
					/>
				) : null}
			</>
			<Basic>
				<Steps active={steps[step]}>
					<Step name="form">
						<FormStep
							amount={amount}
							handleAmountSet={handleAmountSet}
							handleAction={handleAction}
							handleError={handleError}
							showError={showError}
							validated={validated}
						/>
					</Step>
					<Step name="payment">
						<PaymentStep amount={amount} form={form} handleAction={handleAction} showError={showError} validated={validated} />
					</Step>
					<Step name="thank_you">
						<ThankYouStep message={state.donation?.thank_you_page_message} />
					</Step>
				</Steps>
			</Basic>

			<PoweredBy />
		</Widget>
	)
}
