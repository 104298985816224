import React from 'react'
import { type TAction } from '../../interfaces'
import classNames from 'classnames'

export interface IButton {
	text: string
	value?: string
	active?: boolean
	loading?: boolean
	action: TAction
	small?: boolean
	image?: React.ReactNode
}

export const Button = ({ text, value, active, loading, action, small, image }: IButton): React.JSX.Element => (
	<button
		type="button"
		className={classNames({
			'with-loading': loading !== undefined,
			active,
			loading,
			small
		})}
		onClick={() => {
			action(value ?? text)
		}}
		disabled={loading}>
		{image}
		{text}
	</button>
)
